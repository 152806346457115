import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
    const location = useLocation();
    const [auth, setAuth] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        // Agregar el token al encabezado global de Axios
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const checkAuth = async () => {
            try {
                const res = await axios.get('https://web-production-dd62a.up.railway.app', { withCredentials: true });
                setTimeout(() => {
                    if (res.data.Status === 'Exito') {
                        setAuth(true);
                    } else {
                        setAuth(false);
                    }
                    setLoading(false);
                }, 500);
            } catch (error) {
                setTimeout(() => {
                    setAuth(false);
                    setLoading(false);
                }, 500);
            }
        };

        checkAuth();
    }, []);

    if (loading) {
        return (
            <div className="overlay">
                <div className="sk-bounce">
                    <div className="sk-bounce-dot"></div>
                    <div className="sk-bounce-dot"></div>
                    <div className="sk-bounce-dot"></div>
                </div>
            </div>
        );
    }

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
