import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./secciones/Home";
import { Login, Register } from "./secciones/Login";
import {Report, ReportDefault} from "./secciones/Report";
import { Header } from "./secciones/Widgets";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserProvider } from "./context/UserContext";


function App() {
    return (
        <UserProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    
                    <Route element={<ProtectedRoute />}>
                        <Route path='/' element={<Home />} />
                        <Route path='/report' element={<ReportDefault />} />
                        <Route path='/report/:sku' element={<Report />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserProvider>
    );
}

export default App;
