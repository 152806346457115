import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Swal from 'sweetalert2';
import Webcam from 'react-webcam';

const StepForm = ({userInfo, onSubmit  }) => {
    const { sku } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [imageUrl, setImageUrl] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false); // Estado de carga para el paso 0

    const [message, setMessage] = useState('');
    const [report, setReport] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const webcamRef = useRef(null);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [dynamicConfig, setDynamicConfig] = useState(null);
    const [currentChannel, setCurrentChannel] = useState(0); // Canal inicial

    const [formData, setFormData] = useState({
        serialNumber: '',
        model: "",
        brand: "",
        sampler: { type: '', serialNumber: '', description: '', otherSampler: '' },
        serviceDate: new Date().toISOString().split('T')[0],
        serviceType: '',
        generalDescription: '',
        user_id: null, 
        image_name: '',
    });

    const [lc6000FormData, setLc6000FormData] = useState({
        serialNumber: "",
        model: "LC6000",
        brand: "Scion Instruments",
        serviceDate: new Date().toISOString().split("T")[0],
        serviceType: "",
        generalDescription: "",
        user_id: null, 
        image_name: '',
        Organizer: "",
        Pump: "",
        AutoSampler: "",
        ColumnOven: "",
        Detectors: [], // Arreglo vacío para selección múltiple
    });
    

    const [errors, setErrors] = useState({});

    const brandModels = {
        Varian: ["430", "450", "3800", "3900"],
        Bruker: ["430", "436", "450", "456"],
        "Scion Instruments": ["436", "8300", "456", "8500", "LC6000"],
    };

    const modelChannelMap = {
        // Marca Varian
        "3800": ["Frontal", "Medio", "Trasero"], // 3 canales
        "450": ["Frontal", "Medio", "Trasero"], // 3 canales
        "3900": ["Frontal", "Trasero"], // 2 canales
        "430": ["Frontal", "Trasero"], // 2 canales
    
        // Marca Bruker
        "450": ["Frontal", "Medio", "Trasero"], // 3 canales
        "456": ["Frontal", "Medio", "Trasero"], // 3 canales
        "430": ["Frontal", "Trasero"], // 2 canales
        "436": ["Frontal", "Trasero"], // 2 canales
    
        // Marca Scion Instruments
        "8500": ["Frontal", "Medio", "Trasero"], // 3 canales
        "456": ["Frontal", "Medio", "Trasero"], // 3 canales
        "8300": ["Frontal", "Trasero"], // 2 canales
        "436": ["Frontal", "Trasero"], // 2 canales
    };
    
    const lc6000Modules = [
        {
            module: "Organizer",
            options: [
                "SCION 6510 Organizer / Solvent cabinet with Power Supply Module and Interface Control Board",
            ],
        },
        {
            module: "Pump",
            options: [
                "SCION 6100 Pump (60MPa), With manual purge valve",
                "Quaternary Pump Set: 6100 Pump, Low Pressure Gradient Unit and 6-channel degasser",
            ],
        },
        {
            module: "AutoSampler",
            options: [
                "SCION 6210 Autosampler",
                "SCION 6220 AS with Thermostat (220V)",
                "Rheodyne® Manual Injector Rheodyne, 7725i - Includes Mounting Bracket",
            ],
        },
        {
            module: "ColumnOven",
            options: [
                "SCION 6310 Column Oven w. peltier - 220V",
                "SCION 6310 Column Oven w. peltier - 110V",
                "SCION 6320 Vertical Column Oven - 220V",
                "SCION 6320 Vertical Column Oven - 110V",
                "Column Switching Valve Kit",
                "NONE",
            ],
        },
        {
            module: "Detectors",
            options: [
                "SCION 6410 UV Detector",
                "SCION 6430 Diode Array Detector",
                "SCION 6440 FL Detector",
                "SCION 6450 RI Detector",
            ],
            multipleSelection: true, // Detectors allow multiple selection
        },
    ];    

    const channelOptions = {
        injectors: ["S/SL", "PTV", "COC", "PWA", "GSV", "LSV", "NONE"],
        detectors: ["FID", "TCD", "PFPD", "NPD", "ECD", "PDHID", "SCD", "SQ", "TQ", "NONE"],
        requiresSerialAndDescription: ["SQ", "TQ", "SCD"], // Detectores que requieren campos adicionales
        columns: ["Número de parte", "Descripción"],
    };
    
    

    // Llamar al backend para obtener marcas y modelos al cargar el componente

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://web-production-dd62a.up.railway.app/', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
    
                if (response.data.Status === "Exito") {
                    setFormData((prev) => ({ ...prev, user_id: response.data.id }));
                } else {
                    console.error('No se pudo obtener el ID del usuario.');
                }
            } catch (err) {
                console.error('Error al obtener el ID del usuario:', err);
            }
        };
    
        fetchUserId();
    }, []);
    // Fin Validacipon de Datos

     // Manejar el cambio de archivo
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg'];
    
        if (selectedFile && !allowedExtensions.includes(selectedFile.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo no permitido',
                text: 'Solo se permiten archivos .jpg, .jpeg y .png',
            });
            setFile(null);
            setPreview(null);
            return;
        }
    
        setFile(selectedFile);
    
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(selectedFile);
    };

    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
    };

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const file = dataURLtoFile(imageSrc, 'captured_image.jpg');
        setFile(file);
        setPreview(imageSrc);
        setShowWebcam(false);
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    // Subir imagen y avanzar al siguiente paso
    const handleImageUpload = async () => {
        if (!file) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Por favor selecciona una imagen antes de subir.',
            });
            return false;
        }
    
        setIsUploading(true); // Mostrar estado de carga
        const formDataData = new FormData();
        formDataData.append('file', file);
        formDataData.append('userId', userInfo?.id);
    
        try {
            const response = await axios.post(
                `https://web-production-dd62a.up.railway.app/report/upload-temp/${sku}`,
                formDataData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            if (response.data.Status === 'Exito') {
                setImageUrl(response.data.imageUrl);
    
                // Guardar el nombre de la imagen en formData
                setFormData((prev) => ({
                    ...prev,
                    image_name: response.data.imageName, // Asumiendo que el backend devuelve `imageName`
                }));
    
                setIsUploading(false);
                return true;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.Error || 'Error al subir la imagen.',
                });
                setIsUploading(false);
                return false;
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al subir la imagen.',
            });
            setIsUploading(false);
            return false;
        } finally {
            setIsUploading(false); // Finalizar el estado de carga
        }
    };
    

    const handleSubmit = async () => {
        if (!imageUrl) {
            alert('Por favor suba una imagen antes de enviar.');
            return;
        }

        const concatenatedServiceType = formData.serviceType ? formData.serviceType.join(",") : "";
    
        let finalJson = {
            serialNumber: formData.serialNumber,
            model: formData.model,
            brand: formData.brand,
            serviceDate: formData.serviceDate,
            serviceType: concatenatedServiceType,
            generalDescription: formData.generalDescription,
            user_id: formData.user_id,
            image_name: formData.image_name || (imageUrl ? imageUrl.split('/').pop() : ''),
        };
    
        if (formData.model === 'LC6000') {
            // Agregar módulos específicos de LC6000
            finalJson = {
                ...finalJson,
                Organizer: formData.Organizer,
                Pump: formData.Pump,
                AutoSampler: formData.AutoSampler,
                ColumnOven: formData.ColumnOven,
                Detectors: formData.Detectors,
            };
        } else {
            // Construir canales para otros modelos
            const channels = modelChannelMap[formData.model]?.map((channel) => ({
                canal: channel,
                inyector: formData[`injector_${channel}`] || "",
                detector: formData[`detector_${channel}`] || "",
                serialNumber: formData[`serial_${channel}`] || "",
                descripcion: formData[`description_${channel}`] || "",
                columnPart: formData[`columnPart_${channel}`] || "",
                columnDescription: formData[`columnDescription_${channel}`] || "",
            }));
    
            finalJson = {
                ...finalJson,
                channels,
                sampler: formData.sampler,
            };
        }
    
        console.log('JSON Enviado:', JSON.stringify(finalJson, null, 2));
    
        try {
            const response = await axios.post(
                `https://web-production-dd62a.up.railway.app/report/complete/${sku}`,
                finalJson,
                { headers: { 'Content-Type': 'application/json' } }
            );
    
            if (response.data.Status === 'Exito') {
                Swal.fire({
                    icon: 'success',
                    title: 'Información enviada',
                    text: 'El reporte se ha enviado correctamente.',
                    timer: 2000,
                    showConfirmButton: false,
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al guardar el reporte.',
                });
            }
        } catch (err) {
            console.error('Error al enviar el formulario:', err);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al guardar el reporte.',
            });
        }
    };

    function getGeneralSteps({
        formData,
        handleChange,
        handleImageUpload,
        setCurrentStep,
        Swal,
        preview,
        setPreview,
        file,
        setFile,
        isUploading,
        webcamRef,
        showWebcam,
        setShowWebcam,
        handleRemoveImage,
        errors,
    }) {
        const steps = [];
    
        // Paso 0: Subir Imagen
        steps.push({
            title: 'Subir Imagen',
            content: (
                <div>
                    <form className="upload-form">
                        <div className="upload-container">
                            <label htmlFor="fileUpload" className="upload-label">
                                {preview ? (
                                    <div className="upload-preview-container">
                                        <img
                                            src={preview}
                                            alt="Vista previa"
                                            className="upload-preview"
                                        />
                                        <button
                                            type="button"
                                            className="remove-preview"
                                            onClick={handleRemoveImage}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ) : (
                                    <div className="upload-icon">
                                        <i
                                            className="ri-file-upload-line"
                                            style={{ fontSize: '50px' }}
                                        ></i>
                                    </div>
                                )}
                                <span className="upload-text">
                                    {file ? file.name : 'Cargar foto'}
                                </span>
                            </label>
                            <input
                                type="file"
                                id="fileUpload"
                                className="upload-input"
                                onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    if (selectedFile) {
                                        setFile(selectedFile);
                                        setPreview(URL.createObjectURL(selectedFile));
                                    }
                                }}
                            />
                        </div>
                        <div className="upload-buttons">
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect waves-light"
                                onClick={() => {
                                    setShowWebcam(!showWebcam);
                                    if (!showWebcam) {
                                        setPreview(null);
                                        setFile(null);
                                    }
                                }}
                            >
                                {showWebcam ? 'Cerrar Cámara' : 'Abrir Cámara'}
                            </button>
                            {isUploading ? (
                                <button className="btn btn-primary" type="button" disabled>
                                    Cargando...
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={async () => {
                                        const success = await handleImageUpload();
                                        if (success) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Imagen cargada',
                                                timer: 2000,
                                                showConfirmButton: false,
                                            }).then(() => setCurrentStep(1));
                                        }
                                    }}
                                >
                                    Subir Foto
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            ),
        });
    
        // Paso 2: Número de Serie
        steps.push({
            title: 'Número de Serie',
            content: (
                <div className="form-floating form-floating-outline mb-6">
                    <input
                        type="text"
                        name="serialNumber"
                        className="form-control"
                        value={formData.serialNumber}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                            handleChange({ target: { name: 'serialNumber', value: filteredValue } });
                        }}
                        required
                    />
                    <label htmlFor="serialNumber">Ingrese el número de serie</label>
                    {errors.serialNumber && (
                        <small className="text-danger">{errors.serialNumber}</small>
                    )}
                </div>
            ),
        });
    
        // Paso 3: Seleccionar Marca y Modelo
        steps.push({
            title: 'Seleccionar Marca y Modelo',
            content: (
                <div>
                    <div className="form-group mb-4">
                        <div className='mb-6'>
                            <small className='pb-6'>Para concluir el proceso, apóyenos capturando la siguiente información.</small>
                        </div>
                        <div className="form-floating form-floating-outline">
                            <select
                                id="floatingSelect"
                                name="brand"
                                className="form-select"
                                value={formData.brand || ""}
                                onChange={(e) => {
                                    handleChange(e);
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        model: "", // Resetear el modelo cuando cambie la marca
                                    }));
                                }}
                                required
                            >
                                <option value="">Seleccione una marca</option>
                                {Object.keys(brandModels).map((brand) => (
                                    <option key={brand} value={brand}>
                                        {brand}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="floatingSelect">Selecciona la marca:</label>
                        </div>
                        {errors.brand && <small className="text-danger">{errors.brand}</small>}
                    </div>
                    <div className="form-group">
                        <div className="form-floating form-floating-outline">
                            <select
                                id="model"
                                name="model"
                                className="form-select"
                                value={formData.model || ""}
                                onChange={handleChange}
                                required
                                disabled={!formData.brand}
                            >
                                <option value="">Seleccione un modelo</option>
                                {formData.brand &&
                                    brandModels[formData.brand].map((model) => (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    ))}
                            </select>
                            <label htmlFor="model">Selecciona el modelo:</label>
                        </div>
                        {errors.model && <small className="text-danger">{errors.model}</small>}
                    </div>
                </div>
            ),
        });
    
        return steps;
    }

    function getDynamicSteps({ formData, model, handleChange, currentChannel }) {
        const steps = [];
    
        if (model === "LC6000") {
            // Paso 4: Selección de módulos LC6000
            steps.push({
                title: "Configuración de Módulos",
                content: (
                    <div>
                        {lc6000Modules.map((module) => (
                            <div key={module.module} className="form-group mb-4">
                                <h5>{module.module}</h5>
                                {module.multipleSelection ? (
                                    // Manejo de selección múltiple para "Detectors"
                                    <div>
                                        {module.options.map((option) => (
                                            <div key={option} className="form-check">
                                                <input
                                                    type="checkbox"
                                                    id={`${module.module}_${option}`}
                                                    name={`${module.module}_${option}`}
                                                    value={option}
                                                    className="form-check-input"
                                                    checked={formData[module.module]?.includes(option) || false}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        const value = e.target.value;
                                                        const existingValues = formData[module.module] || [];
                                                        handleChange({
                                                            target: {
                                                                name: module.module,
                                                                value: isChecked
                                                                    ? [...existingValues, value]
                                                                    : existingValues.filter((v) => v !== value),
                                                            },
                                                        });
                                                    }}
                                                />
                                                <label
                                                    htmlFor={`${module.module}_${option}`}
                                                    className="form-check-label"
                                                >
                                                    {option}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    // Selección única para otros módulos
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            id={module.module}
                                            name={module.module}
                                            className="form-select"
                                            value={formData[module.module] || ""}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: { name: module.module, value: e.target.value },
                                                })
                                            }
                                        >
                                            <option value="">Seleccione una opción</option>
                                            {module.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor={module.module}>Seleccione {module.module}</label>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ),
            });

            // Paso 5: Configuración de información adicional
            steps.push({
                title: "Información Adicional",
                content: (
                    <div>
                        {/* Día del servicio */}
                        <div className="form-floating form-floating-outline mb-4">
                            <input
                                type="date"
                                id="serviceDate"
                                name="serviceDate"
                                className="form-control"
                                value={formData.serviceDate}
                                disabled
                            />
                            <label htmlFor="serviceDate">Día del Servicio</label>
                        </div>
            
                        {/* Tipo de servicio */}
                        <div className="form-group mb-4">
                            <label className='mb-2'>Tipo de Servicio:</label>
                            <div>
                                {["Install", "PM – Preventive Maintenance", "Repair", "IQ – Installation Qualification", "OQ – Operational Qualification"].map((type) => (
                                    <div key={type} className="form-check">
                                        <input
                                            type="checkbox"
                                            id={`serviceType_${type}`}
                                            name="serviceType"
                                            value={type}
                                            className="form-check-input"
                                            checked={formData.serviceType?.includes(type) || false}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                const value = e.target.value;
                                                const existingValues = formData.serviceType || [];
                                                handleChange({
                                                    target: {
                                                        name: "serviceType",
                                                        value: isChecked
                                                            ? [...existingValues, value] // Agregar si está seleccionado
                                                            : existingValues.filter((v) => v !== value), // Remover si no está seleccionado
                                                    },
                                                });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`serviceType_${type}`}>
                                            {type}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
            
                        {/* Descripción General */}
                        <div className="form-floating form-floating-outline">
                            <textarea
                                id="generalDescription"
                                name="generalDescription"
                                className="form-control h-px-120"
                                value={formData.generalDescription}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "generalDescription",
                                            value: e.target.value,
                                        },
                                    })
                                }
                            />
                            <label htmlFor="generalDescription">Descripción General</label>
                        </div>
                    </div>
                ),
            });

            // Paso 6: Confirmación de Información Adicional (LC6000)
            steps.push({
                title: "Resumen de la Configuración",
                content: (
                    <div className="summary-content">
                        {/* Imagen */}
                        <div className="h-100 text-center">
                            {preview ? (
                                <img
                                    src={preview}
                                    alt="Vista previa"
                                    className="card-img-top mb-3"
                                    style={{ maxHeight: "200px", objectFit: "contain" }}
                                />
                            ) : (
                                <p>No se ha cargado ninguna imagen.</p>
                            )}
                        </div>

                        {/* Botones */}
                        <div className="d-flex justify-content-center mb-4">
                            {/* <button
                                className="btn btn-secondary me-3"
                                onClick={() => setCurrentStep((prev) => prev - 1)}
                            >
                                Editar
                            </button> */}
                            <button
                                style={{textTransform:'none'}}
                                className="btn btn-primary"
                                onClick={handleSubmit}
                            >
                                Confirmar y enviar
                            </button>
                        </div>

                        {/* Información General */}
                        <div className="card-body">
                            <h5 className="card-title mb-3">Información General</h5>
                            <div className="row gx-3">
                                <div className="col-md-4">
                                    <p className="card-text mb-1">
                                        <strong>S/N:</strong> {formData.serialNumber || "No especificado"}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-1">
                                        <strong>Marca:</strong> {formData.brand || "No especificado"}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-1">
                                        <strong>Modelo:</strong> {formData.model || "No especificado"}
                                    </p>
                                </div>
                            </div>
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    <p className="card-text mb-1">
                                        <strong>Día de servicio:</strong> {formData.serviceDate || "No especificado"}
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p className="card-text mb-1">
                                        <strong>Tipo de servicio:</strong>{" "}
                                        {Array.isArray(formData.serviceType)
                                            ? formData.serviceType.join(", ")
                                            : formData.serviceType || "No especificado"}
                                    </p>
                                </div>
                            </div>
                            <p className="card-text mt-2">
                                <strong>Descripción general:</strong> {formData.generalDescription || "No especificado"}
                            </p>
                        </div>

                        {/* Módulos LC6000 */}
                        <div className="card-body">
                            <h5 className="card-title mb-3">Módulos</h5>
                            {[
                                { label: "Organizer", value: formData.Organizer },
                                { label: "Pump", value: formData.Pump },
                                { label: "AutoSampler", value: formData.AutoSampler },
                                { label: "Column Oven", value: formData.ColumnOven },
                            ].map((module, index) => (
                                <p key={index} className="mb-1">
                                    <strong>{module.label}:</strong> {module.value || "No especificado"}
                                </p>
                            ))}
                            {formData.Detectors?.length > 0 && (
                                <div className="mt-3">
                                    <strong>Detectors:</strong>
                                    <ul>
                                        {formData.Detectors.map((detector, index) => (
                                            <li key={index}>{detector}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                ),
            });

            
        } else {
            
            // Paso 4: Configuración de canales
            steps.push({
                title: `Configurar Canal ${modelChannelMap[formData.model]?.[currentChannel] || ""}`,
                content: (
                    <div>
                        {modelChannelMap[formData.model]?.map((channel, index) => 
                            index === currentChannel && ( // Mostrar solo el canal actual
                                <div key={channel} className="channel-config">
                                    <div className="form-floating form-floating-outline mb-4">
                                        <select
                                            id={`injector_${channel}`}
                                            name={`injector_${channel}`}
                                            className="form-select"
                                            value={formData[`injector_${channel}`] || ""}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: `injector_${channel}`,
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="">Seleccione una opción</option>
                                            {channelOptions.injectors.map((injector) => (
                                                <option key={injector} value={injector}>
                                                    {injector}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor={`injector_${channel}`}>
                                            Seleccione el inyector:
                                        </label>
                                    </div>
            
                                    <div className="form-floating form-floating-outline mb-4">
                                        <select
                                            id={`detector_${channel}`}
                                            name={`detector_${channel}`}
                                            className="form-select"
                                            value={formData[`detector_${channel}`] || ""}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: `detector_${channel}`,
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="">Seleccione una opción</option>
                                            {channelOptions.detectors.map((detector) => (
                                                <option key={detector} value={detector}>
                                                    {detector}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor={`detector_${channel}`}>
                                            Seleccione el detector:
                                        </label>
                                    </div>
            
                                    {channelOptions.requiresSerialAndDescription.includes(
                                        formData[`detector_${channel}`]
                                    ) && (
                                        <>
                                            <div className="form-floating form-floating-outline mb-4">
                                                <input
                                                    type="text"
                                                    id={`serial_${channel}`}
                                                    name={`serial_${channel}`}
                                                    className="form-control"
                                                    value={formData[`serial_${channel}`] || ""}
                                                    onChange={(e) =>
                                                        handleChange({
                                                            target: {
                                                                name: `serial_${channel}`,
                                                                value: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                                <label htmlFor={`serial_${channel}`}>
                                                    Número de serie:
                                                </label>
                                            </div>
                                            <div className="form-floating form-floating-outline mb-4">
                                                <textarea
                                                    id={`description_${channel}`}
                                                    name={`description_${channel}`}
                                                    className="form-control h-px-120"
                                                    value={formData[`description_${channel}`] || ""}
                                                    onChange={(e) =>
                                                        handleChange({
                                                            target: {
                                                                name: `description_${channel}`,
                                                                value: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                                <label htmlFor={`description_${channel}`}>
                                                    Descripción:
                                                </label>
                                            </div>
                                        </>
                                    )}
            
                                    <div className="form-group mt-4">
                                        <h6>Sección Columnas</h6>
                                        <div className="form-floating form-floating-outline mb-4">
                                            <input
                                                type="text"
                                                id={`columnPart_${channel}`}
                                                name={`columnPart_${channel}`}
                                                className="form-control"
                                                value={formData[`columnPart_${channel}`] || ""}
                                                onChange={(e) =>
                                                    handleChange({
                                                        target: {
                                                            name: `columnPart_${channel}`,
                                                            value: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                            <label htmlFor={`columnPart_${channel}`}>
                                                Número de parte:
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-floating form-floating-outline">
                                        <textarea
                                            id={`columnDescription_${channel}`}
                                            name={`columnDescription_${channel}`}
                                            className="form-control h-px-120"
                                            value={formData[`columnDescription_${channel}`] || ""}
                                            onChange={(e) =>
                                                handleChange({
                                                    target: {
                                                        name: `columnDescription_${channel}`,
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                        <label htmlFor={`columnDescription_${channel}`}>
                                            Descripción:
                                        </label>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ),
            });

            // Paso 5: Configuración de sampler
            steps.push({
                title: "Selección de Sampler",
                content: (
                    <div>
                        <div className="form-floating form-floating-outline mb-4">
                            <select
                                id="samplerType"
                                name="sampler.type"
                                className="form-control"
                                value={formData.sampler.type}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "sampler.type",
                                            value: e.target.value,
                                        },
                                    })
                                }
                            >
                                <option value="">Seleccione un sampler</option>
                                <option value="8400">8400</option>
                                <option value="8410">8410</option>
                                <option value="VERSA">VERSA</option>
                                <option value="HT3">HT3</option>
                                <option value="OTHER">OTHER</option>
                                <option value="NONE">NONE</option>
                            </select>
                            <label htmlFor="samplerType">Sampler</label>
                        </div>
            
                        {/* Campo adicional para OTHER */}
                        {formData.sampler.type === "OTHER" && (
                            <div className="form-floating form-floating-outline mb-4">
                                <input
                                    type="text"
                                    id="otherSampler"
                                    name="sampler.otherSampler"
                                    className="form-control"
                                    value={formData.sampler.otherSampler || ""}
                                    onChange={(e) =>
                                        handleChange({
                                            target: {
                                                name: "sampler.otherSampler",
                                                value: e.target.value,
                                            },
                                        })
                                    }
                                />
                                <label htmlFor="otherSampler">Indique el Sampler</label>
                            </div>
                        )}
            
                        {/* Número de serie */}
                        <div className="form-floating form-floating-outline mb-4">
                            <input
                                type="text"
                                id="samplerSerialNumber"
                                name="sampler.serialNumber"
                                className="form-control"
                                value={formData.sampler.serialNumber}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "sampler.serialNumber",
                                            value: e.target.value,
                                        },
                                    })
                                }
                            />
                            <label htmlFor="samplerSerialNumber">Número de Serie</label>
                        </div>
            
                        {/* Descripción */}
                        <div className="form-floating form-floating-outline">
                            <textarea
                                id="samplerDescription"
                                name="sampler.description"
                                className="form-control h-px-120"
                                value={formData.sampler.description}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "sampler.description",
                                            value: e.target.value,
                                        },
                                    })
                                }
                            />
                            <label htmlFor="samplerDescription">Descripción</label>
                        </div>
                    </div>
                ),
            });

            // Paso 6: Configuración de información adicional
            steps.push({
                title: "Información Adicional",
                content: (
                    <div>
                        {/* Día del servicio */}
                        <div className="form-floating form-floating-outline mb-4">
                            <input
                                type="date"
                                id="serviceDate"
                                name="serviceDate"
                                className="form-control"
                                value={formData.serviceDate}
                                disabled
                            />
                            <label htmlFor="serviceDate">Día del Servicio</label>
                        </div>
            
                        {/* Tipo de servicio */}
                        <div className="form-group mb-4">
                            <label className='mb-2'>Tipo de Servicio:</label>
                            <div>
                                {["Install", "PM – Preventive Maintenance", "Repair", "IQ – Installation Qualification", "OQ – Operational Qualification"].map((type) => (
                                    <div key={type} className="form-check">
                                        <input
                                            type="checkbox"
                                            id={`serviceType_${type}`}
                                            name="serviceType"
                                            value={type}
                                            className="form-check-input"
                                            checked={formData.serviceType?.includes(type) || false}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                const value = e.target.value;
                                                const existingValues = formData.serviceType || [];
                                                handleChange({
                                                    target: {
                                                        name: "serviceType",
                                                        value: isChecked
                                                            ? [...existingValues, value] // Agregar si está seleccionado
                                                            : existingValues.filter((v) => v !== value), // Remover si no está seleccionado
                                                    },
                                                });
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`serviceType_${type}`}>
                                            {type}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

            
                        {/* Descripción General */}
                        <div className="form-floating form-floating-outline">
                            <textarea
                                id="generalDescription"
                                name="generalDescription"
                                className="form-control h-px-120"
                                value={formData.generalDescription}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: "generalDescription",
                                            value: e.target.value,
                                        },
                                    })
                                }
                            />
                            <label htmlFor="generalDescription">Descripción General</label>
                        </div>
                    </div>
                ),
            });

            // Paso 7: Confirmar infromación
            steps.push({
                title: "Resumen de la Configuración",
                content: (
                    <div className="summary-content">
                        {/* Imagen */}
                        <div className="h-100">
                            {preview ? (
                                <img
                                    src={preview}
                                    alt="Vista previa"
                                    className="card-img-top mb-4"
                                />
                            ) : (
                                <p>No se ha cargado ninguna imagen.</p>
                            )}

                            {/* Botón Confirmar y Enviar */}
                            <div className="text-center mb-4">
                                <button
                                    style={{textTransform:'none'}}
                                    className="btn btn-primary btn-next waves-effect waves-light"
                                    onClick={() => handleSubmit(formData)}
                                >
                                    Confirmar y enviar
                                </button>
                            </div>

                            {/* Información General */}
                            <div className="card-body">
                                <div className="row gx-0">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <p className="card-text mb-1">
                                            <strong>S/N:</strong> {formData.serialNumber || "No especificado"}
                                        </p>
                                        <p className="card-text mb-1">
                                            <strong>Brand:</strong> {formData.brand || "No especificado"}
                                        </p>
                                        <p className="card-text mb-1">
                                            <strong>Model:</strong> {formData.model || "No especificado"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Canales */}
                        {modelChannelMap[formData.model] && modelChannelMap[formData.model].length > 0 && (
                            <div className="card-body pb-0">
                                {modelChannelMap[formData.model].map((channelName, index) => (
                                    <div key={index} className="summary-channel">
                                        <div className="card-subtitle mb-3">
                                            <strong style={{ color: "#043268" }}>Canal: {channelName}</strong>
                                        </div>
                                        <p className="card-text">
                                            <strong>Inyector:</strong> {formData[`injector_${channelName}`] || "No especificado"}
                                        </p>
                                        <p className="card-text">
                                            <strong>Detector:</strong> {formData[`detector_${channelName}`] || "No especificado"}{" "}
                                            {["SCD", "SQ", "TQ"].includes(formData[`detector_${channelName}`]) && (
                                                <>
                                                    <strong>S/N:</strong> {formData[`serial_${channelName}`] || "No especificado"}
                                                </>
                                            )}
                                        </p>
                                        <div className="card-subtitle mb-3">
                                            <strong>Columnas</strong>
                                        </div>
                                        <p>
                                            <strong>Número de Parte:</strong> {formData[`columnPart_${channelName}`] || "No especificado"}
                                        </p>
                                        <p>
                                            <strong>Descripción:</strong> {formData[`columnDescription_${channelName}`] || "No especificado"}
                                        </p>
                                        <hr className="container-m-nx mb-4" />
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Sampler */}
                        {formData.sampler && (
                            <div className="card-body pt-0">
                                <div className="card-subtitle mb-3">
                                    <strong style={{ color: "#043268" }}>Sampler</strong>
                                </div>
                                <p className="card-text">
                                    <strong>Tipo:</strong> {formData.sampler.type || "No especificado"}
                                </p>
                                {formData.sampler.type === "OTHER" && (
                                    <p className="card-text">
                                        <strong>Nombre de sampler:</strong> {formData.sampler.otherSampler || "No especificado"}
                                    </p>
                                )}
                                <p className="card-text">
                                    <strong>S/N:</strong> {formData.sampler.serialNumber || "No especificado"}
                                </p>
                                <p className="card-text">
                                    <strong>Descripción:</strong> {formData.sampler.description || "No especificado"}
                                </p>
                                <hr className="container-m-nx mb-4" />
                            </div>
                        )}

                        {/* Información Adicional */}
                        <div className="card-body pt-0">
                            <div className="card-subtitle mb-3">
                                <strong style={{ color: "#043268" }}>Información Adicional</strong>
                            </div>
                            <p>
                                <strong>Fecha de Servicio:</strong> {formData.serviceDate || "No especificado"}
                            </p>
                            <p>
                                <strong>Tipo de Servicio:</strong>{" "}
                                {Array.isArray(formData.serviceType)
                                    ? formData.serviceType.join(", ")
                                    : formData.serviceType || "No especificado"}
                            </p>
                            <p>
                                <strong>Descripción General:</strong> {formData.generalDescription || "No especificado"}
                            </p>
                            <hr className="container-m-nx mb-4" />
                        </div>
                    </div>
                ),
            });
        }
    
        return steps;
    }

    function handleChange(e) {
        const { name, value } = e.target;
    
        setFormData((prev) => {
            const keys = name.split("."); // Divide el nombre del campo por "."
            if (keys.length === 1) {
                // Si no es un campo anidado
                return {
                    ...prev,
                    [name]: value,
                };
            } else {
                // Si es un campo anidado
                const [parent, child] = keys;
                return {
                    ...prev,
                    [parent]: {
                        ...prev[parent],
                        [child]: value,
                    },
                };
            }
        });
    }
    
    function handleNestedChange(section, field, value) {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    }

    function handleCanalChange(index, field, value) {
        const canales = [...formData.canales];
        canales[index] = { ...canales[index], [field]: value };
        setFormData((prev) => ({
            ...prev,
            canales,
        }));
    }

    const handleNextStep = () => {
        const validationErrors = validateCurrentStep();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            setCurrentStep((prev) => prev + 1);
        }
    };
    
    

    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const validateCurrentStep = () => {
        const stepErrors = {};
    
        switch (currentStep) {
            case 0: // Paso 0: Subir Imagen
                if (!file) {
                    stepErrors.image = 'Es obligatorio subir una imagen.';
                }
                break;
    
            case 1: // Paso 2: Seleccionar Número de Serie
                if (!formData.serialNumber) {
                    stepErrors.serialNumber = 'El número de serie es obligatorio.';
                } else if (!/^[A-Z0-9]+$/i.test(formData.serialNumber)) {
                    stepErrors.serialNumber = 'El número de serie solo puede contener letras y números.';
                }
                break;
    
            case 2: // Paso 3: Seleccionar Marca y Modelo
                if (!formData.brand) {
                    stepErrors.brand = 'Es obligatorio seleccionar una marca.';
                }
                if (!formData.model) {
                    stepErrors.model = 'Es obligatorio seleccionar un modelo.';
                }
                break;
    
            case 3: // Paso 4: Configuración Dinámica
                if (formData.model === "LC6000") {
                    // Validar módulos LC6000
                    lc6000Modules.forEach((module) => {
                        if (module.multipleSelection) {
                            // Validar selección múltiple (detectors)
                            if (!formData[module.module] || formData[module.module].length === 0) {
                                stepErrors[module.module] = `Seleccione al menos un ${module.module}.`;
                            }
                        } else {
                            // Validar selección única
                            if (!formData[module.module]) {
                                stepErrors[module.module] = `Seleccione un ${module.module}.`;
                            }
                        }
                    });
                } else {
                    // Validar canales para otros modelos
                    modelChannelMap[formData.model]?.forEach((channel, index) => {
                        const canal = formData[`channel_${index}`];
                        if (!canal?.injector) {
                            stepErrors[`channel_${index}_injector`] = 'Seleccione un inyector.';
                        }
                        if (!canal?.detector) {
                            stepErrors[`channel_${index}_detector`] = 'Seleccione un detector.';
                        }
                        if (['SQ', 'TQ', 'SCD'].includes(canal?.detector) && !canal?.serialNumber) {
                            stepErrors[`channel_${index}_serialNumber`] = 'Ingrese el número de serie del detector.';
                        }
                        if (!canal?.columnPN) {
                            stepErrors[`channel_${index}_columnPN`] = 'Ingrese el número de parte.';
                        }
                        if (!canal?.columnDescription) {
                            stepErrors[`channel_${index}_columnDescription`] = 'Ingrese la descripción.';
                        }
                    });
                }
                break;
    
            case 4: // Paso 5: Configuración de Información Adicional
                if (!formData.serviceType) {
                    stepErrors.serviceType = 'Seleccione el tipo de servicio.';
                }
                if (!formData.generalDescription) {
                    stepErrors.generalDescription = 'La descripción general es obligatoria.';
                }
                break;
    
            case 5: // Paso 6: Confirmación de Información
                // Validación adicional opcional en la confirmación, si es necesario
                break;
    
            default:
                break;
        }
    
        return stepErrors;
    };
    

    const steps = useMemo(() => {
        const generalSteps = getGeneralSteps({
            formData,
            handleChange,
            handleImageUpload,
            setCurrentStep,
            Swal,
            preview,
            setPreview,
            file,
            setFile,
            isUploading,
            webcamRef,
            showWebcam,
            setShowWebcam,
            handleRemoveImage,
            errors,
        });
    
        const dynamicSteps = getDynamicSteps({
            formData,
            model: formData.model,
            handleChange,
            currentChannel,
        });
    
        return [...generalSteps, ...dynamicSteps];
    }, [formData, preview, file, currentChannel]);
    

    return (
        <div>
            <h3>{steps[currentStep].title}</h3>
            {steps[currentStep].content}
            {currentStep > 0 && (
                <div className="col-12 d-flex justify-content-between mt-4">
                    {/* Botón Anterior o Editar */}
                    <button
                        className="btn btn-outline-secondary btn-prev waves-effect"
                        onClick={() => {
                            if (currentStep === steps.length - 1) {
                                // Volver al paso 6 para editar si estás en el último paso
                                setCurrentStep((prev) => prev - 1);
                            } else if (currentStep === 3 && currentChannel > 0) {
                                // Retrocede entre los canales en el paso 4
                                setCurrentChannel((prev) => prev - 1);
                            } else {
                                // Retrocede entre los pasos
                                setCurrentStep((prev) => prev - 1);
                            }
                        }}
                        disabled={currentStep === 0 && currentChannel === 0}
                    >
                        <i className="ri-arrow-left-line me-sm-1 me-0"></i>
                        <span className="align-middle d-sm-inline-block">
                            {currentStep === steps.length - 1 ? "Editar" : "Anterior"}
                        </span>
                    </button>
    
                    {/* Botón Siguiente o Confirmar y Enviar */}
                    <button
                        style={{textTransform:'none'}}
                        className="btn btn-primary btn-next waves-effect waves-light"
                        onClick={() => {
                            if (currentStep === 3 && modelChannelMap[formData.model] && currentChannel < modelChannelMap[formData.model].length - 1) {
                                // Avanzar entre los canales
                                setCurrentChannel((prev) => prev + 1);
                            } else if (currentStep === steps.length - 1) {
                                // Lógica para enviar el formulario
                                handleSubmit(formData);
                            } else {
                                // Avanzar entre los pasos
                                setCurrentStep((prev) => prev + 1);
                                setCurrentChannel(0); // Reinicia el canal cuando cambias de paso
                            }
                        }}
                    >
                        {currentStep === steps.length - 1 ? "Confirmar y enviar" : "Siguiente"}
                    </button>
                </div>
            )}
        </div>
    );
    

    
    
    
};

export default StepForm;
